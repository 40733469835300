<template>
    <div class="yike-index">
        <el-scrollbar class="yike-index-scrollbar">
            <div v-swiper:mySwiper="bannerOption" class="home-banner">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" :key="bannerItem.banner_id" v-for="bannerItem in bannerList">
                        <img :src="bannerItem.pic_url_path.filepath" class="banner-img">
                    </div>
                </div>
                <div class="swiper-pagination"></div>
                <div class="banner-swiper-btn-prev">
                    <img alt="Vue logo" src="../../assets/images/left-icon.png">
                </div>
                <div class="banner-swiper-btn-next">
                    <img alt="Vue logo" src="../../assets/images/right-icon.png">
                </div>
            </div>
            <div class="yike-content">
                <div class="content-bg">
                    <img src="../../assets/images/index_1.png" alt="">
                </div>
                <div class="content-item">
                    <div class="item-title">
                        <span class="chinese">一课全程化人才培养体系</span>
                        <span class="english">PERSONNEL TRAINNING SYSTEM</span>
                    </div>
                    <div class="introduction">
                        坚持以“岗位引导、项目驱动”为核心的双模式（应用技能型与复合创业型）电商全程化人才培养体系，以市场机制与产业需求为导向创新人才培养机制与培养目标，构建产教融合的校企联合育人模式。
                    </div>
                    <div class="item-img">
                        <img src="../../assets/images/index_2.png" alt="">
                    </div>
                </div>
                <div class="content-item-bg">
                    <div class="item-bg"></div>
                    <div class="content-item">
                        <div class="item-title">
                            <span class="chinese">课程教学  技能实践</span>
                            <span class="english">COURSE TEACHING  SKILLS PRACTICE</span>
                        </div>
                        <div class="introduction">
                            打造边学边做的一站式实践教学模式
                        </div>
                        <div class="module-list">
                            <div class="module-item" v-for="item in teachModule">
                                <div class="module-icon">
                                    <i class="iconfont" v-html="item.icon"></i>
                                </div>
                                <div class="module-title">{{item.title}}</div>
                                <ul>
                                    <li v-for="moduleItem in item.module_list">{{moduleItem.name}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item dynamic">
                    <div class="item-title">
                        <span class="chinese">一课动态</span>
                        <span class="english">DYNAMIC</span>
                    </div>
                    <div class="dynamic-content">
                        <div class="dynamic-item">
                            <div class="header">
                                <div class="header-title">
                                    <i class="iconfont">&#xe652;</i>
                                    <span class="text">通知</span>
                                </div>
                                <div class="more" @click="viewMore('1')">更多></div>
                            </div>
                            <div class="bg">
                                <img src="../../assets/images/index_5.png" alt="">
                            </div>
                            <div class="recommend-list">
                                <el-scrollbar class="recommend-list-scrollbar" v-if="recommendNoticeList.length > 0">
                                    <div class="recommend-item" v-for="item in recommendNoticeList">
                                        <img src="../../assets/images/index_7.png" alt="" class="triangle">
                                        <div class="dynamic-info">
                                            <div class="dynamic-title" :title="item.title" @click="viewNotice(item.notice_id)">{{item.title}}</div>
                                            <div class="time-status">
                                                <span class="time">{{item.addtime}}</span>
                                                <span class="status">{{Number(item.status) ? '已结束' : '进行中'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </el-scrollbar>
                                <div class="no-data" v-if="recommendNoticeList.length === 0">
                                    <img src="../../assets/images/null-data.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="dynamic-item">
                            <div class="header">
                                <div class="header-title">
                                    <i class="iconfont">&#xe614;</i>
                                    <span class="text">新闻</span>
                                </div>
                                <div class="more" @click="viewMore('2')">更多></div>
                            </div>
                            <div class="bg">
                                <img src="../../assets/images/index_6.png" alt="">
                            </div>
                            <div class="recommend-list">
                                <el-scrollbar class="recommend-list-scrollbar" v-if="recommendNewsList.length > 0">
                                    <div class="recommend-item" v-for="item in recommendNewsList">
                                        <img src="../../assets/images/index_7.png" alt="" class="triangle">
                                        <div class="dynamic-info">
                                            <div class="dynamic-title" :title="item.title" @click="viewNews(item.news_id)">{{item.title}}</div>
                                            <div class="time-status">
                                                <span class="time">{{item.addtime}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </el-scrollbar>
                                <div class="no-data" v-if="recommendNewsList.length === 0">
                                    <img src="../../assets/images/null-data.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item-bg coopration">
                    <div class="item-bg"></div>
                    <div class="content-item">
                        <div class="item-title">
                            <span class="chinese">合作院校</span>
                            <span class="english">COOPRATION</span>
                        </div>
                        <div class="coopration-content">
                            <div class="carousel-title">
                                <div class="title"
                                     v-for="item in collegeTab"
                                     :class="{current: currentTab === item.id}"
                                     :key="item.id"
                                     @click="changeCollegeTab(item.id)">
                                    <span class="text">{{item.name}}</span>
                                </div>
                            </div>
                            <div class="introduction">
                                合作院校已达600多所，服务内容包括：专业建设、产教融合基地建设、教材联合出版、信息化教学平台建设、现代学徒制建设、校企合作、技能大赛等。
                            </div>
                            <swiper :auto-update="true" :options="collegeOption" ref="mySwiper" class="college-banner">
                                <swiper-slide class="swiper-slide" v-for="item in collegeList" :key="item.id">
                                    <img :src="item.pic" class="banner-img">
                                </swiper-slide>
                                <div class="college-swiper-btn-prev" slot="button-prev">
                                    <div class="swiper-btn">
                                        <i class="el-icon-arrow-left"></i>
                                    </div>
                                </div>
                                <div class="college-swiper-btn-next" slot="button-next">
                                    <div class="swiper-btn">
                                        <i class="el-icon-arrow-right"></i>
                                    </div>
                                </div>
                            </swiper>
                        </div>
                    </div>
                </div>
                <div class="content-item-bg cooperative-enterprise">
                    <div class="bg">
                        <img src="../../assets/images/index_3.png" alt="">
                    </div>
                    <div class="content-item">
                        <div class="item-title">
                            <span class="chinese">合作企业</span>
                            <span class="english">COOPERATIVE  ENTERPRISE</span>
                        </div>
                        <div class="cooperative-enterprise-list">
                            <div class="list-item" v-for="item in cooperativeEnterprise">
                                <div class="item-img" v-for="listItem in item.list">
                                    <div class="img">
                                        <img :src="listItem.src" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OfficialFooter />
        </el-scrollbar>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    import OfficialFooter from '../../components/OfficialFooter';
    import {banner, notice, news} from '../../utils/apis'

    export default {
        name: 'Index',
        data() {
            return {
                bannerList: [],
                bannerOption: {
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false
                    },
                    initialSlide: 1,
                    autoHeight: true,
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.banner-swiper-btn-next',
                        prevEl: '.banner-swiper-btn-prev',
                    },
                    observer: true,
                    observeParents: true,
                },
                //课程教学  技能实践
                teachModule: [
                    {
                        icon: '&#xe64a;',
                        title: '课程体系',
                        module_list: [
                            {
                                id: '1',
                                name: '美工岗位课程'
                            },
                            {
                                id: '2',
                                name: '客服岗位课程'
                            },
                            {
                                id: '3',
                                name: '运营岗位课程'
                            },
                            {
                                id: '4',
                                name: '新媒体岗位课程'
                            }
                        ]
                    },
                    {
                        icon: '&#xe615;',
                        title: '实训实战',
                        module_list: [
                            {
                                id: '1',
                                name: '实训平台'
                            },
                            {
                                id: '2',
                                name: '实战平台'
                            },
                            {
                                id: '3',
                                name: '企业项目'
                            },
                            {
                                id: '4',
                                name: '创业实战'
                            }
                        ]
                    },
                    {
                        icon: '&#xe62e;',
                        title: '师资服务',
                        module_list: [
                            {
                                id: '1',
                                name: '教学导师'
                            },
                            {
                                id: '2',
                                name: '实战导师'
                            },
                            {
                                id: '3',
                                name: '国培计划'
                            },
                            {
                                id: '4',
                                name: '双师培训'
                            }
                        ]
                    }
                ],
                //推荐通知
                recommendNoticeList: [],
                //推荐新闻
                recommendNewsList: [],
                //合作院校
                collegeTab: [
                    {
                        id: '1',
                        name: '本科'
                    },
                    {
                        id: '2',
                        name: '高职'
                    },
                    {
                        id: '3',
                        name: '中职'
                    },
                    {
                        id: '4',
                        name: '技工'
                    }
                ],
                currentTab: '1',
                collegeList: [],
                benkeList: [
                    {
                        id: '1',
                        pic: require('../../assets/images/college/1/1-01.jpg')
                    },
                    {
                        id: '2',
                        pic: require('../../assets/images/college/1/1-02.jpg')
                    },
                    {
                        id: '3',
                        pic: require('../../assets/images/college/1/1-03.jpg')
                    },
                    {
                        id: '4',
                        pic: require('../../assets/images/college/1/1-04.jpg')
                    },
                    {
                        id: '5',
                        pic: require('../../assets/images/college/1/1-05.jpg')
                    }
                ],
                gaozhiList: [
                    {
                        id: '1',
                        pic: require('../../assets/images/college/2/2-01.jpg')
                    },
                    {
                        id: '2',
                        pic: require('../../assets/images/college/2/2-02.jpg')
                    },
                    {
                        id: '3',
                        pic: require('../../assets/images/college/2/2-03.jpg')
                    },
                    {
                        id: '4',
                        pic: require('../../assets/images/college/2/2-04.jpg')
                    },
                    {
                        id: '5',
                        pic: require('../../assets/images/college/2/2-05.jpg')
                    },
                    {
                        id: '6',
                        pic: require('../../assets/images/college/2/2-06.jpg')
                    },
                    {
                        id: '7',
                        pic: require('../../assets/images/college/2/2-07.jpg')
                    },
                    {
                        id: '8',
                        pic: require('../../assets/images/college/2/2-08.jpg')
                    },
                    {
                        id: '9',
                        pic: require('../../assets/images/college/2/2-09.jpg')
                    },
                    {
                        id: '10',
                        pic: require('../../assets/images/college/2/2-10.jpg')
                    },
                ],
                zhongzhiList: [
                    {
                        id: '1',
                        pic: require('../../assets/images/college/3/3-01.jpg')
                    },
                    {
                        id: '2',
                        pic: require('../../assets/images/college/3/3-02.jpg')
                    },
                    {
                        id: '3',
                        pic: require('../../assets/images/college/3/3-03.jpg')
                    },
                    {
                        id: '4',
                        pic: require('../../assets/images/college/3/3-04.jpg')
                    },
                    {
                        id: '5',
                        pic: require('../../assets/images/college/3/3-05.jpg')
                    },
                    {
                        id: '6',
                        pic: require('../../assets/images/college/3/3-06.jpg')
                    },
                    {
                        id: '7',
                        pic: require('../../assets/images/college/3/3-07.jpg')
                    },
                    {
                        id: '8',
                        pic: require('../../assets/images/college/3/3-08.jpg')
                    },
                    {
                        id: '9',
                        pic: require('../../assets/images/college/3/3-09.jpg')
                    },
                    {
                        id: '10',
                        pic: require('../../assets/images/college/3/3-10.jpg')
                    }
                ],
                jigongList: [
                    {
                        id: '1',
                        pic: require('../../assets/images/college/4/4-01.jpg')
                    },
                    {
                        id: '2',
                        pic: require('../../assets/images/college/4/4-02.jpg')
                    },
                    {
                        id: '3',
                        pic: require('../../assets/images/college/4/4-03.jpg')
                    },
                    {
                        id: '4',
                        pic: require('../../assets/images/college/4/4-04.jpg')
                    },
                    {
                        id: '5',
                        pic: require('../../assets/images/college/4/4-05.jpg')
                    },
                    {
                        id: '6',
                        pic: require('../../assets/images/college/4/4-06.jpg')
                    },
                    {
                        id: '7',
                        pic: require('../../assets/images/college/4/4-07.jpg')
                    },
                    {
                        id: '8',
                        pic: require('../../assets/images/college/4/4-08.jpg')
                    },
                    {
                        id: '9',
                        pic: require('../../assets/images/college/4/4-09.jpg')
                    },
                    {
                        id: '10',
                        pic: require('../../assets/images/college/4/4-10.jpg')
                    },
                    {
                        id: '11',
                        pic: require('../../assets/images/college/4/4-11.jpg')
                    },
                    {
                        id: '12',
                        pic: require('../../assets/images/college/4/4-12.jpg')
                    },
                    {
                        id: '13',
                        pic: require('../../assets/images/college/4/4-13.jpg')
                    },
                    {
                        id: '14',
                        pic: require('../../assets/images/college/4/4-14.jpg')
                    },
                    {
                        id: '15',
                        pic: require('../../assets/images/college/4/4-15.jpg')
                    },
                    {
                        id: '16',
                        pic: require('../../assets/images/college/4/4-16.jpg')
                    },
                    {
                        id: '17',
                        pic: require('../../assets/images/college/4/4-17.jpg')
                    },
                    {
                        id: '18',
                        pic: require('../../assets/images/college/4/4-18.jpg')
                    },
                    {
                        id: '19',
                        pic: require('../../assets/images/college/4/4-19.jpg')
                    },
                    {
                        id: '20',
                        pic: require('../../assets/images/college/4/4-20.jpg')
                    },
                    {
                        id: '21',
                        pic: require('../../assets/images/college/4/4-21.jpg')
                    },
                    {
                        id: '22',
                        pic: require('../../assets/images/college/4/4-22.jpg')
                    },
                    {
                        id: '23',
                        pic: require('../../assets/images/college/4/4-23.jpg')
                    },
                    {
                        id: '24',
                        pic: require('../../assets/images/college/4/4-24.jpg')
                    },
                ],
                collegeOption: {
                    // autoplay: {
                    //     delay: 3000,
                    //     disableOnInteraction: false
                    // },
                    slidesPerView: 5,
                    initialSlide: 1,
                    autoHeight: true,
                    loop: false,
                    navigation: {
                        nextEl: '.college-swiper-btn-next',
                        prevEl: '.college-swiper-btn-prev',
                    },
                    observer: true,
                    observeParents: true,
                },
                //合作企业
                cooperativeEnterprise: [
                    {
                        list: [
                            {
                                id: '1',
                                src: require('../../assets/images/businessCooperation/1.png')
                            },
                            {
                                id: '2',
                                src: require('../../assets/images/businessCooperation/2.png')
                            },
                            {
                                id: '3',
                                src: require('../../assets/images/businessCooperation/3.png')
                            },
                            {
                                id: '4',
                                src: require('../../assets/images/businessCooperation/4.png')
                            }
                        ]
                    },
                    {
                        list: [
                            {
                                id: '5',
                                src: require('../../assets/images/businessCooperation/5.png')
                            },
                            {
                                id: '6',
                                src: require('../../assets/images/businessCooperation/6.png')
                            },
                            {
                                id: '7',
                                src: require('../../assets/images/businessCooperation/7.png')
                            },
                            {
                                id: '8',
                                src: require('../../assets/images/businessCooperation/8.png')
                            }
                        ]
                    },
                    {
                        list: [
                            {
                                id: '9',
                                src: require('../../assets/images/businessCooperation/9.png')
                            },
                            {
                                id: '10',
                                src: require('../../assets/images/businessCooperation/10.png')
                            },
                            {
                                id: '11',
                                src: require('../../assets/images/businessCooperation/11.png')
                            },
                            {
                                id: '12',
                                src: require('../../assets/images/businessCooperation/12.png')
                            }
                        ]
                    },
                    {
                        list: [
                            {
                                id: '13',
                                src: require('../../assets/images/businessCooperation/13.png')
                            },
                            {
                                id: '14',
                                src: require('../../assets/images/businessCooperation/14.png')
                            },
                            {
                                id: '15',
                                src: require('../../assets/images/businessCooperation/15.png')
                            },
                            {
                                id: '16',
                                src: require('../../assets/images/businessCooperation/16.png')
                            }
                        ]
                    }
                ]
            }
        },
        components: {
            Swiper,
            SwiperSlide,
            OfficialFooter
        },
        directives: {
            swiper: directive
        },
        created() {
          this.getBannerList();
          this.getRecommendNoticeList();
          this.getRecommendNewsList();
        },
        mounted() {
          if (this.currentTab === '1') {
              this.collegeList = this.benkeList;
          }
        },
        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper
            }
        },
        methods: {
            //获取轮播图列表
            getBannerList() {
                let params = {
                    type: '1'
                }
                banner(params).then((res) => {
                    this.bannerList = res.data.list;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //获取通知列表
            getRecommendNoticeList() {
                let params = {
                    type: 1
                }
                notice(params).then((res) => {
                    this.recommendNoticeList = res.data.list;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //获取新闻列表
            getRecommendNewsList() {
                let params = {
                    type: 1
                }
                news(params).then((res) => {
                    this.recommendNewsList = res.data.list;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //查看通知详情
            viewNotice(id) {
                let routeUrl = this.$router.resolve({
                    path: '/state/index',
                    query: {
                        notice_id: id
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            //查看新闻详情
            viewNews(id) {
                let routeUrl = this.$router.resolve({
                    path: '/state/index',
                    query: {
                        news_id: id
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            //查看更多
            viewMore(type) {
                let routeUrl = this.$router.resolve({
                    path: '/state/index',
                    query: {
                        type: type
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            //切换院校
            changeCollegeTab(id) {
                this.currentTab = id;
                if (id === '1') {
                    this.collegeList = this.benkeList;
                } else if (id === '2') {
                    this.collegeList = this.gaozhiList;
                } else if (id === '3') {
                    this.collegeList = this.zhongzhiList;
                } else {
                    this.collegeList = this.jigongList;
                }
            }
        }
    }
</script>
<style scoped lang="scss">
    .yike-index {
        width: 100%;
        height: 100%;
        .yike-index-scrollbar {
            height: 100%;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        .home-banner {
            width: 100%;
            height: 466px;
            /*background: linear-gradient(101deg, #E7545F 0%, #F7B579 100%);*/
            .banner-img {
                display: block;
                /*width: 100%;*/
                /*max-height: 640px;*/
            }
            ::v-deep .swiper-pagination-bullets {
                .swiper-pagination-bullet {
                    width: 30px;
                    height: 3px;
                    background: #fff;
                    border-radius: 0;
                }
            }
            .banner-swiper-btn-prev,
            .banner-swiper-btn-next {
                width: 15%;
                position: absolute;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 10;
                opacity: .5;
                cursor: pointer;
                img {
                    display: block;
                    width: 20px;
                }
                &:hover {
                    opacity: 1;
                }
            }
            .banner-swiper-btn-prev {
                left: 0;
            }
            .banner-swiper-btn-next {
                right: 0;
            }
        }
        .yike-content {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 4257px;
            .content-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .content-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 1200px;
                margin: 0 auto;
                padding: 80px 0;
                .item-title {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .chinese {
                        color: #222222;
                        font-size: 30px;
                        margin-bottom: 10px;
                    }
                    .english {
                        color: #999999;
                        font-size: 14px;
                    }
                }
                .introduction {
                    margin-top: 50px;
                    color: #333333;
                    font-size: 16px;
                    line-height: 2;
                    text-indent:35px
                }
                .item-img {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 591px;
                    margin-top: 50px;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            .content-item-bg {
                position: relative;
                width: 100%;
                padding: 0;
                .content-item {
                    padding: 30px 0;
                }
                .item-bg {
                    position: absolute;
                    width: 100%;
                    height: 800px;
                    background-color: #999;
                    opacity: 0.1;
                }
                .introduction {
                    color: #333333;
                    font-size: 24px;
                }
            }
            .module-list {
                display: flex;
                justify-content: space-between;
                width: 1200px;
                margin-top: 69px;
                .module-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 350px;
                    height: 484px;
                    background: #FFFFFF;
                    border: 1px solid #EEEEEE;
                    opacity: 0.8;
                    border-radius: 6px;
                    cursor: pointer;
                    padding: 45px;
                    box-sizing: border-box;
                    &:hover {
                        border: 1px solid #FFFFFF;
                        box-shadow: 0 4px 9px 0 rgba(3, 23, 94, 0.09), 0 0 23px 1px rgba(3, 23, 94, 0.09);
                    }
                    .module-icon {
                        width: 110px;
                        height: 110px;
                        text-align: center;
                        line-height: 110px;
                        background: linear-gradient(-81deg, #F96662 0%, #FCB790 100%);
                        border-radius: 50%;
                        i {
                            color: #fff;
                            font-size: 46px
                        }
                    }
                    .module-title {
                        color: #222222;
                        font-size: 24px;
                        margin: 55px 0 40px 0;
                    }
                    ul {
                        padding: 0;
                        li {
                            color: #444444;
                            font-size: 18px;
                            list-style-type: none;
                            line-height: 1.8;
                            &:before {
                                content: "\2022";
                                color: #F96662;
                                font-size: 18px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
            .dynamic-content {
                display: flex;
                width: 1200px;
                .dynamic-item {
                    display: flex;
                    flex-direction: column;
                    width: calc((100% - 32px)/2);
                    z-index: 9;
                    &:first-child {
                        margin-right: 32px;
                    }
                    .header {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 40px;
                        .header-title {
                            i {
                                color: #584EEE;
                                font-size: 22px;
                                margin-right: 10px;
                            }
                            .text {
                                color: #333333;
                                font-size: 18px;
                            }
                        }
                        .more {
                            color: #999999;
                            font-size: 14px;
                            cursor: pointer;
                        }
                    }
                    .bg {
                        width: 100%;
                        height: 288px;
                        margin: 28px 0;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .recommend-list {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: 404px;
                        .recommend-list-scrollbar {
                            height: 100%;
                            ::v-deep .el-scrollbar__wrap {
                                overflow-x: hidden;
                            }
                        }
                        .recommend-item {
                            position: relative;
                            width: 100%;
                            height: 90px;
                            background-color: #ffffff70;
                            border-bottom: 1px solid #EEEEEE;
                            cursor: pointer;
                            margin-bottom: 10px;
                            .triangle {
                                position: absolute;
                            }
                            &:hover {
                                box-shadow: 0 0 8px 0 rgba(4, 55, 132, 0.17);
                                border-radius: 10px;
                                background-color: #fff;
                            }
                            .dynamic-info {
                                display: flex;
                                flex: 1;
                                flex-direction: column;
                                justify-content: space-between;
                                width: 100%;
                                padding: 20px;
                                box-sizing: border-box;
                                .dynamic-title {
                                    color: #333;
                                    font-size: 18px;
                                    overflow: hidden;
                                    text-overflow:ellipsis;
                                    white-space: nowrap;
                                    cursor: pointer;
                                }
                                .time-status {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    .time {
                                        color: #999999;
                                        font-size: 16px;
                                    }
                                    .status {
                                        display: block;
                                        width: 80px;
                                        height: 26px;
                                        line-height: 26px;
                                        text-align: center;
                                        background: #F36F21;
                                        border-radius: 6px;
                                        color: #fff;
                                        font-size: 12px;
                                        margin-right: 15px;
                                    }
                                }
                            }
                        }
                    }
                    .no-data {
                        margin-top: 80px;
                        text-align: center;
                    }
                }
            }
            .coopration {
                height: 560px;
                .item-bg {
                    height: 560px;
                    background-color: #f7fafb;
                    opacity: 1;
                }
                .content-item {
                    position: relative;
                    height: 100%;
                    box-sizing: border-box;
                    .coopration-content {
                        .carousel-title {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 360px;
                            margin: 40px auto 0;
                            background-color: #CCDDFA;
                            border-radius: 20px;
                        }
                        .title {
                            width: 90px;
                            height: 39px;
                            line-height: 39px;
                            text-align: center;
                            font-size: 18px;
                            color: #fff;
                            cursor: pointer;
                            &:first-child {
                                border-radius: 20px 0 0 20px;
                            }
                            &:last-child {
                                border-radius: 0 20px 20px 0;
                            }
                            &:hover {
                                color: #333333;
                                background-color: #fff;
                                box-shadow: 0 0 13px 0 rgba(3, 23, 94, 0.15);
                                border-radius: 20px;
                            }
                        }
                        .current {
                            color: #333333;
                            background-color: #fff;
                            box-shadow: 0 0 13px 0 rgba(3, 23, 94, 0.15);
                            border-radius: 20px !important;
                        }
                        .introduction {
                            font-size: 16px;
                        }
                    }
                }
                .college-banner {
                    position: absolute;
                    width: 100%;
                    height: 180px;
                    margin: 50px auto 0;
                    ::v-deep .swiper-wrapper {
                        /*margin: 0 0 0 145px;*/
                    }
                    .swiper-slide {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 188px !important;
                        height: 180px;
                        margin-right: 53px;
                        &:first-child {
                            margin-left: 10px;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    .banner-img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                    ::v-deep .swiper-pagination-bullets {
                        .swiper-pagination-bullet {
                            width: 30px;
                            height: 3px;
                            background: #fff;
                            border-radius: 0;
                        }
                    }
                    .college-swiper-btn-prev,
                    .college-swiper-btn-next {
                        position: absolute;
                        top: 50px;
                        bottom: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 10;
                        width: 15%;
                        cursor: pointer;
                        .swiper-btn {
                            width: 50px;
                            height: 80px;
                            line-height: 80px;
                            text-align: center;
                            background: #ffffff;
                            border-radius: 10px;
                            color: #fff;
                            font-size: 20px;
                            opacity: 0.5;
                        }
                        &:hover {
                            .swiper-btn {
                                background: #584EEE;
                                opacity: 1;
                                i {
                                    color: #fff;
                                }
                            }
                        }
                        i {
                            color: #584EEE;
                            font-weight: 600;
                        }
                    }
                    .college-swiper-btn-prev {
                        left: 0;
                    }
                    .college-swiper-btn-next {
                        right: 0;
                    }
                }
            }
            .cooperative-enterprise {
                width: 100%;
                height: 880px;
                background-color: #fff;
                .bg {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .content-item {
                    position: relative;
                }
                .cooperative-enterprise-list {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    .list-item {
                        display: flex;
                        width: 100%;
                        .item-img {
                            width: calc((100% - 30px) / 2);
                            height: 127px;
                            background: #FFFFFF;
                            border: 1px solid #EEEEEE;
                            border-radius: 6px;
                            margin-right: 10px;
                            &:hover {
                                box-shadow: 0 0 8px 0 rgba(3, 23, 94, 0.09), 0 4px 8px 0 rgba(3, 23, 94, 0.09);
                            }
                            &:last-child {
                                margin-right: 0;
                            }
                            .img {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                width: 108px;
                                height: 47px;
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (width: 1680px) {
        .home-banner {
            height: 407px !important;
        }
        .banner-img {
            width: 100%;
        }
    }
</style>